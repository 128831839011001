<template>
  <cw-page
    icon="local_offer"
    class="brokered-loan__loan-offer"
  >
    <template #hero-title>
      {{ heroTitle }}
    </template>

    <v-form ref="cw-application__form" autocomplete="off">
      <cw-loan-offer-info
        v-bind="{
          isAppliedProduct,
          offerTypes,
          selectedOfferType,
        }"
      />

      <cw-price-cards
        v-if="hasOffers"
        :offer-id.sync="offerId"
        :offers="offers"
      />

      <v-slide-y-transition origin="center top">
        <v-row
          v-if="offerId === null && submitted"
          justify="center"
        >
          <v-col class="text-center">
            <div class="cw-application--error">
              <div
                id="errors--offers"
                class="error--text"
              >
                <translate>
                  Select an offer to continue
                </translate>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-slide-y-transition>
      <cw-form-loader v-if="$wait.waiting('updateOffer')"/>
    </v-form>

    <template #actions>
      <v-spacer/>
      <v-btn
        id="submit"
        :disabled="$wait.waiting('SUBMIT_FORM') || $wait.waiting('page__init')"
        :loading="$wait.waiting('SUBMIT_FORM')"
        :dark="!$wait.waiting('SUBMIT_FORM') && !$wait.waiting('page__init')"
        color="purple-one"
        @click="
          submit();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>
          Continue
        </translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DataLayer } from '@shared/services/dataLayer';

export default {
  name: 'CwBrokeredLoanOffer',

  components: {
    'cw-loan-offer-info': () => import('@shared/components/LoanOfferInfo'),
    'cw-price-cards': () => import('@shared/components/LoanOfferPriceCards'),
  },

  data: () => ({
    heroTitle: '',
    offerId: null,
    selectedOfferType: null,
    submitted: false,
  }),

  computed: {
    /**
     * Import getter functions from store
     */
    ...mapGetters({
      mobile: 'getMobile',
      product: 'application/getProduct',
      offers: 'application/getBrokeredOffers',
      initialData: 'application/getInitialData',
    }),

    hasOffers() {
      return this.offers.length > 0;
    },

    isAppliedProduct() {
      if (!this.offerTypes.length) return true;

      return this.offerTypes.length === 1 && this.offerTypes.includes(this.product.loanType);
    },

    isInstalmentLoan() {
      return this.offers[0].type === 'brokeredInstalmentLoan';
    },

    offerTypes() {
      return [...new Set(this.offers.map(offer => offer.type))];
    },
  },

  async created() {
    // recieve the selected offer id from the offer cards
    this.$bus.$on('SELECTED_OFFER_ID__SET', id => this.setSelectedOfferId(id));

    this.$wait.start('updateOffer');
    try {
      await this.loadBrokeredOffers();

      this.$wait.end('updateOffer');
      this.heroTitle = this.$ngettext('We can offer the following product', 'We can offer the following products', this.offers.length);
      this.$dataLayer.PushToECommerce({ params: { action: 'impressions' } });

      this.onImpressionView();

      return false;
    } catch (e) {
      return false;
    }
  },

  methods: {
    ...mapActions({
      loadBrokeredOffers: 'application/loadBrokeredOffers',
      onImpressionView: 'dataLayer/onImpressionView',
      setSelectedOffer: 'application/setSelectedOffer',
      submitForm: 'application/submit',
      submitSelectedOffer: 'application/submitSelectedOffer',
    }),

    setSelectedOfferId(id) {
      this.offerId = id;

      const selectedOffer = this.offers.find(offer => offer.id === id);

      this.selectedOfferType = selectedOffer.type;

      this.setSelectedOffer(selectedOffer);
    },

    /**
     * Submit step data
     */
    async submit() {
      this.submitted = true;
      this.$dataLayer.PushToECommerce({ params: { action: 'stepValidationClick' } });

      DataLayer.onValidationClick();

      if (this.offerId === null) {
        this.$vuetify.goTo('.offerSelectionButton', {
          duration: 200,
          offset: -120,
          easing: 'easeInOutCubic',
        });

        DataLayer.onValidationFail();

        return;
      }

      this.$dataLayer.PushToECommerce({ params: { action: 'stepValidationSubmit' } });
      this.$wait.start('SUBMIT_FORM');

      const selectedOffer = this.offers.filter(offer => offer.id === this.offerId)[0];

      const params = {
        formParams: {
          selectedOffer,
        },
        routineParams: {
          uri: 'executeRoutine',
          routine: 'Submit Loan Offer Selection',
        },
      };

      try {
        await this.submitSelectedOffer(params);
      } catch (e) {
        this.$wait.end('SUBMIT_FORM');
      }
    },
  },
};
</script>
